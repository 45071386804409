import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const FidgetSpinner = () => (
  <Layout
    title="Fidget Spinner - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Fidget Spinner</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Fidget Spinner</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../rainbow-bubble-snake" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="de416e5a-8caf-4008-b877-a38e90c79e30"
                className="de-vid"
              ></Video>
              <Link to="../liquid-fireworks" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Your teacher might not love them in class, but the physics behind
              fidget spinners are truly head-spinning! Join 3M scientist Tesha
              R.-Alston Dampier as she shows you how a spinning motion changes
              the way things move.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              Fidget spinners work due to their unique design: three weights
              spin in unison around a central bearing. The rapid movement of the
              weights combined with gravitational forces create the sensation of
              weight when manipulating the spinning toy. Today we are going to
              examine some of the fascinating science behind fidget spinners.
              Sure, they spin, but why do they spin so smoothly, and for so
              long? Why is it difficult to flip one over mid-spin? Let’s try to
              answer these questions with an experiment.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Force</li>
              <li>Motion</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Fidget spinner</li>
              <li>Pencil or chopstick</li>
              <li>12 inches of string</li>
              <li>Tape</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>Tie a loop in one end of the string.</li>
              <li>
                Tape the other end of the string to a shelf or table, so the
                loop is dangling free.
              </li>
              <li>
                Put the pencil or chopstick through the middle hole of the
                fidget spinner so there is only about 1 inch from the fidget
                spinner to the end of the pencil or chopstick.
              </li>
              <li>
                Make a prediction. What will happen when the pencil is only
                supported by the string on one side?
              </li>
              <li>Spin the fidget spinner so it is going fast.</li>
              <li>
                Put the short end of the pencil or chopstick into the loop of
                string, being careful not to tangle the string in the fidget
                spinner.
              </li>
              <li>Let the pencil or chopstick go and see what happens. </li>
              <li>
                Be sure to clean up when you are all done. Throw the tape away
                and put the other materials back where you found them.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              The pencil should stay balanced parallel to the ground and spin
              around in a circle. If that doesnâ€™t happen, try spinning the
              fidget spinner the opposite direction and try again. This happens
              because of the way that a spinning object changes the way that
              forces act on it. This is called gyroscopic precession and happens
              when a spinning object is suspended on an axle. The torque or
              rotational force of the fidget spinner is pushing outward from the
              axle, in this case a pencil or chopstick, which allows it to stay
              suspended and turn slowly in a circle. Gyroscopic precession is
              what allows a bike to stay upright while itâ€™s moving, and why
              you have to put your foot down to balance when you stop.
            </p>
            <p>
              The way fidget spinners are built makes them great for this
              experiment, but that doesn't mean they are our only option. For
              example, bikes were mentioned, and if you have a bike wheel
              available, maybe you could find a way to set this up with a string
              or rope and a bike wheel in a garage or outside. What else can you
              find that you could easily spin on some kind of axle like a
              pencil, a chopstick, or a stick?
            </p>
            <p>
              We suggested about an inch between the fidget spinner and the
              string, but what happens if you use more or less distance? If you
              find some distances that don't work, can you figure out or
              research why they don't? If you're spinning other objects, what
              distances work for those specific objects, and why might they be
              different than, or the same as the distance for a fidget spinner?
            </p>
            <p>
              Lastly, what happens if the string is moving? Does it change the
              behavior of the spinning object? Does the way the string is moving
              make a difference? Remember to be aware of where youâ€™re doing
              the experiment and whatâ€™s around you!
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS2.A: Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS1-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS1-2.</em> Pushing or pulling on an object can
                        change the speed or direction of its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS1-1.</em> Each force acts on one particular
                        object and has both strength and a direction.
                      </li>
                      <li>
                        <em>3-PS1-2.</em> The patterns of an objectâ€™s motion
                        in various situations can be observed and measured: when
                        that past motion exhibits a regular patter, future
                        motion can be predicted from it.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-2.</em> The motion of an object is determined
                        by the sum of the forces acting on it; if the total
                        force on the object is not zero, its motion will change.
                        The greater the mass of the object, the greater the
                        force needed to achieve the same change in motion.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-1.</em> Newton's second law accurately
                        predicts changes in the motion of macroscopic objects.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS2.B: Types of Interactions
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> When objects touch or collide, they
                        push on one another and can change motion.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        3-PS2-1. Objects in contact exert forces on each other.
                      </li>
                      <li>
                        3-PS2-1. The gravitational force of Earth acting on an
                        object near Earthâ€™s surface pulls that object toward
                        the planetâ€™s center.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS3.A: Definitions of Energy
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-1.</em> The faster a given object is moving,
                        the more energy it possesses.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-1.</em> Motion energy is properly called
                        kinetic energy; it is proportional to the mass of the
                        moving object and grows with the square of its speed.
                      </li>
                      <li>
                        <em>MS-PS3-2.</em> A system of objects may also contain
                        stored (potential) energy, depending on their relative
                        positions.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-1.</em> Energy is a quantitative property of
                        a system that depends on the motion and interactions of
                        matter and radiation within that system.
                      </li>
                      <li>
                        <em>HS-PS3-3.</em> At the macroscopic scale, energy
                        manifests itself in multiple ways, such as in motion.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    PS3.B: Conservation of Energy and Energy Transfer
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-2.</em> Energy is present whenever there are
                        moving objects.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-5.</em> When the motion energy of an object
                        changes, there is inevitably some other change in energy
                        at the same time.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-1.</em> Conservation of energy means that the
                        total change of energy in any system is always equal to
                        the total energy transferred into or out of the system.
                      </li>
                      <li>
                        <em>HS-PS3-4.</em> Uncontrolled systems always evolve
                        toward more stable states.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck5"></input>
                  <label className="label" for="chck5">
                    PS3.C: Relationship Between Energy and Force
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS3-1.</em> A bigger push or pull makes things go
                        faster.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-3.</em> When objects collide, the contact
                        forces transfer energy so as to change the objects'
                        motions.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-2.</em> When two objects interact, each one
                        exerts a force on the other that can cause energy to be
                        transferred to or from the object.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck6"></input>
                  <label className="label" for="chck6">
                    PS3.D: Energy in Chemical Processes and Everyday Life
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-4.</em> The expression "produce energy"
                        typically refers to the conversion of stored energy into
                        a desired form for practical use.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-3.</em> Although energy cannot be destroyed,
                        it can be converted to less useful forms.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="FidgetSpinner-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Tesha Alston Dampier</h3>
                <h4>Scientist, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="RainbowSnake-Thumbnail.png" />
              <div className="card__content">
                <h3>Rainbow Bubble Snake</h3>
                <h4>
                  Everyone loves bubbles, but have you ever thought about how
                  they form?
                </h4>
                <p>
                  In this video, special guest, Kate the Chemist, shows you the
                  science behind bubbles. With just a few simple materials,
                  you’ll learn how to make a bright and colorful bubble snake
                  using your breath, soap, water and a plastic water bottle.
                </p>
              </div>
              <Link
                to="../rainbow-bubble-snake"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="LiquidFireworks-Thumbnail.png" />
              <div className="card__content">
                <h3>Liquid Fireworks</h3>
                <p>
                  Join 3M scientist Jeff Payne as he uses nothing more than
                  milk, dish soap, and a few other kitchen supplies to get the
                  amazing effects of fireworks without using any fire at all.
                </p>
              </div>
              <Link
                to="../liquid-fireworks"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="SoapBoat-Thumbnail.png" />
              <div className="card__content">
                <h3>Soap Boat</h3>
                <h4>
                  Join 3M Researcher Vasav Shani as he introduces you to the
                  science of surface tension.
                </h4>
                <p>
                  Not only is it only important  for many engineering and earth
                  science processes, it also makes blowing bubbles possible.
                </p>
              </div>
              <Link to="../soap-boat" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FidgetSpinner;
